<template>
  <div class="visualization-like-list-container">
    <div class="like-list-tag">
      <div class="like-list-tag-title">
        <img src="../../../assets/image/tag.png" alt="" class="like-list-img" />
        <span class="like-list-name">{{lang.room_likes}}</span>
      </div>
      <div class="like-list-label">
        <span class="like-list-name">{{lang.total}}:</span>
        <span class="like-list-num">{{ likeTotal }}</span>
      </div>
    </div>
    <!-- 图表 -->
    <div
      class="like-list-echarts"
      id="like-echarts"
      style="height: 100%; width: 100%"
    ></div>
  </div>
</template>

<script>
import { getLikeList } from "@/utils/apis";
import lang from "@/lang/advlist"
export default {
  data() {
    return {
      lang:lang,
      likeListEcharts: null,
      timer: null,
      likeTotal: "",
      likeTime: [],
      likeData: [],
    };
  },
    props: ["train", "studentInfo"],
  mounted() {
    this.likeListEcharts = this.$echarts.init(
      document.getElementById("like-echarts")
    );
    let resizeTimer = null;
    // 因为主页面存在多个图表，仅最后一个组件执行了自适应，所以要使用window.addEventListener的方式去监听自适应
    // 子组件中的window.onresize只获取到了最后一个echarts
    // 因为箭头函数会改变this指向，指向windows。所以先把this保存
    const self = this;
    window.addEventListener("resize", function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        self.likeListEcharts.resize();
      }, 10);
    });
    self.showLikeListEcharts();
    this.getLikeList(3000);
  },
  methods: {
    getLikeList(seconds) {
      this.timer = window.setInterval(() => {
        let params = {
           user_id: this.train ? this.train.user_id : this.studentInfo?this.studentInfo.user_id:'',
          train_id: this.train ? this.train.train_id : this.studentInfo?this.studentInfo.train_id:'',
          room_id: this.train ? this.train.room_id : this.studentInfo?this.studentInfo.room_id:'',
        };
        getLikeList(params)
          .then((res) => {
            let time = [];
            let like = [];
            if (res.code === 200) {
              this.likeTotal = res.data.like_total;
              res.data.list.forEach((item) => {
                time.push(item.create_time);
                like.push(item.like);
              });
              this.likeTime = time;
              this.likeData = like;
              this.showLikeListEcharts();
            }
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
          });
      }, seconds);
    },
    showLikeListEcharts() {
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
        },
        legend: {
          icon: "rect",
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: [this.lang.total_likes, this.lang.like_count],
          right: "35%",
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
          bottom: "5%",
        },
        grid: {
          left: "1.5%",
          right: "5%",
          bottom: "7%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: "rgba(0,0,0,0)",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.7,
                color: "rgba(75, 69, 255, 0.09)",
              },
            },
            data: this.likeTime,
          },
        ],
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 0,
        //     end: 100,
        //   },
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 65,
        //     end: 85,
        //   },
        // ],
        dataZoom: [
          {
            show: true,
            height: 12,
            xAxisIndex: [0],
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            bottom: "2.5%",
            start: 0,
            end: 100,
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 1,
            end: 35,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.lang.unit,
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(0,0,0,0)",
              },
            },
            axisLabel: {
              margin: 15,
              textStyle: {
                fontSize: 14,
                color: "#fff",
              },
            },
            splitLine: {
              lineStyle: {
                opacity: 0.9,
                color: "rgba(75, 69, 255, 0.09)",
              },
            },
          },
        ],
        series: [
          {
            name: this.lang.now_likes,
            type: "line",
            smooth: true,
            symbol: "none",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 3,
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(75, 69, 255, 1)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(75, 69, 255, 0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(75, 69, 255, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(75, 69, 255, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(75, 69, 255, 0.1)",
                  },
                ]),
              },
              emphasis: {
                color: "rgba(75, 69, 255, 1)",
                borderColor: "rgba(75, 69, 255, 0.2)",
                extraCssText: "box-shadow: 8px 8px 8px rgba(0, 0, 0, 1);",
                borderWidth: 10,
              },
            },
            data: this.likeData,
          },
        ],
      };
      this.likeListEcharts.setOption(option);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.visualization-like-list-container {
  height: 94.5%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  .like-list-tag {
    display: flex;
    margin: 20px 0 27px 21px;
    .like-list-tag-title {
      flex: 1;
      .like-list-img {
        margin-right: 11px;
      }
      .like-list-name {
        font-size: 16px;
        color: #fff;
      }
    }
    .like-list-label {
      margin-right: 19px;
      .like-list-name {
        font-size: 16px;
        color: #ffffff;
      }
      .like-list-num {
        font-size: 16px;
        color: #4b45ff;
      }
    }
  }
  .like-list-echarts {
    margin-bottom: 17px;
    bottom: 15%;
  }
}
</style>
