<!-- 商品列表 -->
<template>
  <div class="visualization-goods-list-container">
    <div class="goods-list-tag">
      <div class="goods-list-tag-title">
        <img src="../../../assets/image/tag.png" alt="" class="goods-list-tag-img" />
        <span class="goods-list-name">{{ lang.product_list }}</span>
      </div>
      <div class="goods-list-label">
        <el-radio v-model="radio" label="1" class="goods-list-label-radio"
          @change="changeGoodList">{{ lang.sorting }}</el-radio>
        <el-radio v-model="radio" label="2" class="goods-list-label-radio"
          @change="changeGoodList">{{ lang.sales_sorting }}</el-radio>
      </div>
    </div>

    <div class="visualization-goods-list-main" style="padding: 0.05rem 0.1rem">
      <el-table :data="tableData" style="width: 100%" class="deepBlueTable">
        <el-table-column prop="goods_id" :label="lang.numebr" width="50" align="center" type="index">
        </el-table-column>
        <el-table-column prop="goods_name" :label="lang.information" width="190">
          <template slot-scope="scope">
            <div class="goods-info">
              <img style="width: 50px; height: 50px" :src="`https://kuajing.e-class.me/${scope.row.goods_master_img}`"
                alt="" class="goods-img" />
              <span style="margin-left: 9px;display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;">{{ scope.row.goods_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_discount_price" :label="lang.live_room_price" width="90" align="center">
          <template slot-scope="scope">
            <span style="color: red">￥{{ scope.row.goods_discount_price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="click_rate" :label="lang.click_rate" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.click_rate }}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="conversion_rate" :label="lang.conversion_rate" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.conversion_rate }}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_num" :label="lang.orders" width="120" align="center">
        </el-table-column>
        <el-table-column prop="sales" :label="lang.sales_volume" width="120">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getGoodsDataList } from "@/utils/apis";
import lang from "@/lang/advlist"
export default {
  data() {
    return {
      lang: lang,
      timer: "",
      radio: 0,
      listPages: {
        currentPageNum: 1,
        eachPageNum: 5,
        total: 0,
      },
      tableData: [],
    };
  },
  props: ["train", "studentInfo"],
  mounted() {
    this.showDataList(3000);
  },
  methods: {
    showDataList(seconds) {
      this.timer = window.setInterval(() => {
        let params = {
          user_id: this.train
            ? this.train.user_id
            : this.studentInfo
              ? this.studentInfo.user_id
              : "",
          train_id: this.train
            ? this.train.train_id
            : this.studentInfo
              ? this.studentInfo.train_id
              : "",
        };
        if (this.radio != null) {
          params.goods_sort = this.radio;
        }
        getGoodsDataList(params).then((res) => {
          if (res.code === 200) {
            this.tableData = res.data;
          }
        });
      }, seconds);
    },
    changeGoodList() {
      let params = {
        goods_sort: this.radio,
        user_id: this.train
          ? this.train.user_id
          : this.studentInfo
            ? this.studentInfo.user_id
            : "",
        train_id: this.train
          ? this.train.train_id
          : this.studentInfo
            ? this.studentInfo.train_id
            : "",
      };
      getGoodsDataList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.visualization-goods-list-container {
  height: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 0.2rem;
  overflow: hidden;

  .goods-list-tag {
    display: flex;
    margin: 0.2rem 0 0.1rem 0.21rem;

    .goods-list-tag-title {
      flex: 1;

      .goods-list-tag-img {
        margin-right: 0.12rem;
      }

      .goods-list-name {
        font-size: 0.16rem;
        color: #fff;
      }
    }

    .goods-list-label {
      margin-right: 0.19rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      ::v-deep .goods-list-label-radio {
        .el-radio__label {
          color: #fff;
        }

        .el-radio__inner {
          background-color: transparent;
          border: 2px solid #4b45ff;
          border-radius: 50%;
          color: #ffffff;
        }

        .el-radio__input.is-radio+.el-radio__label {
          color: rgb(255, 255, 255);
        }

        .el-radio__input.is-radio .el-radio__inner {
          background-color: transparent;
          border: 2px solid #4b45ff;
          border-radius: 100%;
        }

        .el-radio__input.is-checked .el-radio__inner::after {
          background: #4b45ff;
        }
      }
    }
  }

  .visualization-goods-list-main {
    .goods-info {
      display: flex;
      align-items: center;

      .goods-img {
        border-radius: 7px;
      }
    }

    .font-settings {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      position: absolute;
      left: 30%;
      top: 15%;
    }
  }
}
</style>
