<template>
  <div class="visualization-screen-main-container">
    <div class="screen-main-header">
      <div class="main-header-title">{{lang.amount_rmb}}</div>
      <div class="main-header-total">
        <span class="iconfont icon-financial_fill">&#xe74b;</span>
        <div class="main-header-money">
          {{ mainData.sales ? mainData.sales : "0" }}
        </div>
      </div>
    </div>

    <div class="screen-main-footer">
      <div class="main-footer-dealNum">
        <div class="main-footer-title">{{lang.deal}}</div>
        <div class="main-footer-num">
          {{ mainData.deal_number ? mainData.deal_number : "0" }}
        </div>
      </div>
      <div class="main-footer-dealNum">
        <div class="main-footer-title">{{lang.products}}</div>
        <div class="main-footer-num">
          {{ mainData.goods_shelves_num ? mainData.goods_shelves_num : "0" }}
        </div>
      </div>
      <div class="main-footer-dealNum">
        <div class="main-footer-title">{{lang.highest}}</div>
        <div class="main-footer-num">
          {{
            mainData.highest_order_num ? mainData.highest_order_num : "0"
          }}
        </div>
      </div>
      <div class="main-footer-dealNum">
        <div class="main-footer-title">{{lang.new_fans}}</div>
        <div class="main-footer-num">
          {{
            mainData.now_in_online_number ? mainData.now_in_online_number : "0"
          }}
        </div>
      </div>

      <div class="main-footer-dealNum">
        <div class="main-footer-title">{{lang.real_time}}</div>
        <div class="main-footer-num">
          {{
            mainData.online_total_number ? mainData.online_total_number : "0"
          }}
        </div>
      </div>
      <div class="main-footer-dealNum">
        <div class="main-footer-title">{{lang.average}}</div>
        <div class="main-footer-num">
          {{ mainData.ave_unit_price ? mainData.ave_unit_price : "0" }}
        </div>
      </div>
      <div class="main-footer-dealNum">
        <div class="main-footer-title">{{lang.maximum_sales}}</div>
        <div class="main-footer-num">
          {{ mainData.highest_sales ? mainData.highest_sales : "0" }}
        </div>
      </div>
      <div class="main-footer-dealNum">
        <div class="main-footer-title">{{lang.accumulated}}</div>
        <div class="main-footer-num">
          {{ mainData.in_online_number ? mainData.in_online_number : "0" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lang from "@/lang/advlist"
export default {
  props: ["mainData"],
  data() {
    return {
      lang:lang,
      liveTime: "",
      timer: null,
      price: 0,
      newFans: 0, //新增粉丝数
      dealGoodsNum: 0, //成交件数
      dealPeopleNum: 0, //成交人数
      conversion: 0, //转化率
      onlineNumber: 0, //在线人数
      watchNumber: 0, //观看人数
      watchTime: 0, //观看时长
      obj: {},
    };
  },
  mounted() {},

  methods: {
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.visualization-screen-main-container {
  height: 100%;
  width: 100%;
  background: linear-gradient(153deg, #12c4c4, #4b45ff);
  border: 1px solid rgba(67, 154, 255, 0.15);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  padding: 0.41rem 0.61rem;
  box-sizing: border-box;
  .screen-main-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    &:nth-of-type(1) {
      margin-bottom: 0.29rem;
    }
    &:nth-of-type(2) {
      margin-bottom: 0.29rem;
    }
    &:nth-of-type(3) {
      margin-bottom: 0.29rem;
    }
    &:nth-of-type(4) {
      margin-bottom: 0.29rem;
    }
    .main-header-title {
      font-size: 0.18rem;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 0.28rem;
    }
    .main-header-total {
      display: flex;
      align-items: flex-end;
      .icon-financial_fill {
        font-size: 0.4rem;
        color: #ffffff;
      }
      .main-header-money {
        height: 0.58rem;
        line-height: 0.58rem;
        font-size: 0.8rem;
        font-weight: bold;
        color: #ffffff;
        margin-left: 0.2rem;
      }
    }

    .main-header-money-status {
      width: 0.2rem;
      height: 0.2rem;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 0.08rem;
      text-align: center;
      color: #ffffff;
      margin-top: 0.05rem;
      font-size: 0.14rem;
    }
  }

  .screen-main-footer {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-between;
    .main-footer-dealNum {
      margin-bottom: 0.23rem;
      width: 25%;
      text-align: center;
      .main-footer-title {
        height: 0.14rem;
        line-height: 0.14rem;
        font-size: 0.14rem;
        color: #ffffff;
        opacity: 0.6;
        margin-bottom: 0.18rem;
      }
      .main-footer-num {
        height: 0.3rem;
        line-height: 0.3rem;
        font-size: 0.3rem;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .main-footer-online-total {
      display: flex;
      justify-content: flex-start;
    }
  }
}
</style>
