<template>
  <div class="visualization-screen-data-container">
    <!-- 名称 -->
    <div class="screen-data-title">{{lang.commerce}}</div>
    <!-- 全屏按钮 -->
    <div
      class="screen-data-fullscreen iconfont icon-fullScreen"
      @click="handleClick_fullscreen"
    >
      &#xe65e;{{lang.full}}
    </div>
    <div class="screen-data-info">
      <div class="screen-data-info-left">
        <!-- 头像 -->
        <img
          class="screen-data-avatar"
          v-if="!mainData.avatar"
          src="../../../assets/image/blank_headPic.png"
        />
        <img
          class="screen-data-avatar"
          v-if="mainData.avatar"
          :src="`http://onlive.e-class.me/${mainData.avatar}`"
        />
        <!-- 主播名称 -->
        <div class="screen-data-name">{{ mainData.nickname }}</div>
        <!-- 直播中 -->
        <div class="iconfont icon-zhibozhong-0">
          {{ mainData.nickname ? lang.streaming : lang.broadcasting }}&#xe621;
        </div>
      </div>
      <div class="screen-data-info-right">
        <!-- 开播时间 -->
        <div class="screen-data-time data-center-info-common">
          {{lang.airtime}}：{{
            mainData.onlive_start_time ? mainData.onlive_start_time : lang.no_data
          }}
        </div>
        <!-- 开播时长 -->
        <div class="screen-data-duration data-center-info-common">
          {{
            mainData.onlive_duration
              ? lang.already + Math.floor(mainData.onlive_duration/60) + lang.minute
              : lang.no_data
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rem from "@/utils/rem";
import screenfull from "screenfull";
import lang from "@/lang/advlist"

export default {
  data(){
    return{
      lang:lang,
    }
  },
  props: ["mainData"],
  mounted() {
    // console.log(this.mainData.onlive_duration);
  },
  methods: {
    handleClick_fullscreen() {
      screenfull.toggle();
    },
  },
};
</script>

<style lang="scss" scoped>
.data-center-info-common {
  height: 26px;
  line-height: 26px;
  font-size: 14px;
}
.visualization-screen-data-container {
  padding: 0 0.23rem 0 0.13rem;
  color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 0.08rem 0 0.2rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .screen-data-title {
    font-size: 0.3rem;
    height: 0.3rem;
    line-height: 0.3rem;
    font-weight: bold;
    text-align: center;
  }
  .screen-data-fullscreen {
    position: absolute;
    right: 0;
    top: 8%;
    width: 80px;
    height: 26px;
    line-height: 26px;
    background: #4b45ff;
    border-radius: 13px;
    text-align: center;
    cursor: pointer;
  }
  .screen-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.14rem;
    .screen-data-info-left {
      display: flex;
      align-items: center;
      margin-right: 0.24rem;
      .screen-data-avatar {
        width: 0.28rem;
        height: 0.28rem;
        border: 0.01rem solid #ffffff;
        border-radius: 50%;
        margin-right: 0.1rem;
      }
      .screen-data-name {
        font-weight: bold;
        margin-right: 0.1rem;
      }
      .icon-zhibozhong-0 {
        margin-left: 10px;
        width: 80px;
        height: 26px;
        background: #fe3266;
        border-radius: 13px;
        text-align: center;
        line-height: 26px;
        font-size: 0.14rem;
      }
    }
    .screen-data-info-right {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
